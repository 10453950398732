<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md" @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5><br>
				<CRow class="mb-2">
                <CCol lg="12" class="mb-3">
				<Datepicker v-model="filter.date_from" input-class="form-control" placeholder="Due Date from" />
                </CCol>
                <CCol lg="12" class="mb-3">
                    <Datepicker v-model="filter.date_to" input-class="form-control" placeholder="Due Date To" />
                </CCol>
                <CCol lg="12" class="mb-0">
                    <CInput v-model="filter.personnel" placeholder="Personnel"> </CInput>
                </CCol>
                <CCol lg="12" class="mb-3">
                    <v-select label="setting_name" :options="taskList.data" :reduce="(item) => item.id"
                        v-model="filter.renewal_task_id" placeholder="Renewal Type">
                    </v-select>
                </CCol>
                <CCol lg="12" class="mb-3">
                        <h6 class="text-danger"> {{error_msg}}</h6>
                </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
        
            
            <CButton class="float-right" size="sm" color="success" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" target="_blank" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../../config.js';
import axios from '../../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
export default {
    components: { vSelect, Datepicker },
    mounted() {
        this.getRenewalTask();
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters : {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
				personnel: '',
				renewal_task_id: '',
				date_from: '',
				date_to: '',
				company_id: '',
				status:''
			},
           
            taskList:[],
            no_filter:false,
            currentPage:1,
            error_msg: '',
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded : false
        }
    },
    methods: {
        // async download() {
        //     if(
                
		// 		this.filter.personnel ||
		// 		this.filter.renewal_task_id ||
		// 		this.filter.date_from ||
		// 		this.filter.date_to ||
		// 		this.filter.status
        //     ){
        //         this.ready_download = false;
        //         this.processing = true;
        //         this.no_filter = false;
        //         var current_location_id = this.filter.current_location_id;
        //         if(current_location_id == "all" || current_location_id == null){
        //             current_location_id = ""
        //         }
        //         var origin_id = this.filter.origin_id;
        //         if(origin_id == "All" || origin_id == null){
        //             origin_id = ""
        //         } 
        //         var status = this.filter.status;
        //         if(status == "all" || status == null){
        //             status = ""
        //         } 
             
        //         await axios.get(config.api_path+'/reports/furnishing?'+
        //         'description='+this.filter.description+
        //         '&current_location_id='+current_location_id+
        //         '&origin_id='+origin_id+
        //         '&status='+this.filter.status+
        //         '&depot_id='+this.filter.depot_id
        //         ).then(response=>{
        //             this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
        //             this.watchDownload(response.data.file);
                   
        //             this.no_filter = false;
        //         })
        //         .catch(err => {
        //             // this.$showLoading(false)
        //             this.no_filter = true;
        //             this.ready_download = false;
        //             this.processing = false;
        //         });
        //     }
        //     else{
        //         this.no_filter = true;
        //         this.ready_download = false;
        //         this.processing = false;
        //     }
        // },

        dataValidate(){
            if(
                this.filter.personnel ||
				this.filter.renewal_task_id ||
                (this.filter.date_from && this.filter.date_to && this.dateRangeValidate)
               
            ){
                return true;
            }
            else{
                return false;
            }
        },
        dateRangeValidate(){
            let from = this.filter.date_from;
            let to = this.filter.date_to;
            if(!from&&!to){
                return false;
            }else{
                if(from&&to){
                    const start = new Date(from);
                    const end = new Date(to);
                    // Calculate the difference in milliseconds
                    const differenceInMilliseconds = end - start;
                    // Calculate the difference in months
                    const differenceInMonths = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                    // Check if the difference is not greater than 6 months
                    return differenceInMonths <= 6;
                }
                else{
                    return false;
                }
            }
        },
        dateFilterValidatePrompt(){
            let from = this.filter.date_from;
            let to = this.filter.date_to;
            if(from&&to){
                const start = new Date(from);
                const end = new Date(to);
                // Calculate the difference in milliseconds
                const differenceInMilliseconds = end - start;
                // Calculate the difference in months
                const differenceInMonths = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                // Check if the difference is not greater than 6 months
                if(!(differenceInMonths <= 6)){
                    this.error_msg = 'Filter date should be maximum of 6 months.';
                }
            }
        },
        async download() {
            let msg =  'Please select a filter before downloading the report.';
            this.report_downloaded = false;
            if(true || this.dateRangeValidate()){ // no more date limit
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                var date_from = this.filter.date_from;
                if(date_from != ""){
                    date_from = moment(date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }
                else{
                    date_from = '-'
                }
                var date_to = this.filter.date_to;
                if(date_to != ""){	
                    date_to = moment(date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                    date_to = date_to + 86399; 
                }else{
                    date_to = '-'
                }

                await axios.post(`${config.api_path}/reports/personnel-renewal-reminder`, {
                  
                        personnel: this.filter.personnel,
                        renewal_task_id: this.filter.renewal_task_id,
                        date_from : this.filter.date_from,
                        date_to : this.filter.date_to,
                        company_id : config.global_user_data.company_id,
                        status: this.filter.status
                    
                })
               
                // await axios.get(config.api_path+'/export-personnel-license?'+
				// +'&personnel='+personnel
				// +'&renewal_task_id='+renewal_task_id
				// +'&date_from='+date_from
				// +'&date_to='+date_to
				// +'&limit=20')
	    	.then(response=>{
				this.$showLoading(false)
                // this.processing = false;
                // this.ready_download = true;
				// window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
                this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                this.watchDownload(response.data.file);
                this.error_msg = '';
				setTimeout(() => {
					// axios.get(`${config.api_path}/remove-file`,{
					// 	params : {
					// 		filename : response.data.file
					// 	}
					// })	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
		
            }
            else{
                msg = 'Please select due date from and due date to.'
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = msg;
                this.dateFilterValidatePrompt();
            }
        },
        
        getRenewalTask() {
			axios.get(config.api_path + "/reference/settings-list", {
				params: {
					setting_type: "license_renewal_task",
				},
			})
			.then((response) => {
				this.taskList = response.data;
			});
		},
       
        
        clear(){
           
            this.filter.personnel = '';
            this.filter.renewal_task_id = '';
            this.filter.date_from = '';
            this.filter.date_to = '';
            this.filter.status = '';
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>
